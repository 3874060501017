import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const NationalGiannaCenterLandingPage1 = () => (
  <PhysicianLandingPage
    physician="The Gianna Center"
    institution="Gianna Center"
    referralCode="GIANNA1"
    physicianURL="https://www.nationalgiannacenter.org/"
  />
)

export default NationalGiannaCenterLandingPage1
